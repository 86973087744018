
/*
image hover experiment
.container {
    position: relative;
    width: 50%;
}

.image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
    cursor: pointer;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.container:hover .image {
    opacity: 0.3;
}

.container:hover .middle {
    opacity: 1;
}

.text {
    background-color: rgba(136, 136, 139, 0.5);
    color: white;
    font-size: 10px;
    padding: 16px 32px;
    opacity: 0.8;
}
image hover experiment

*/





/*image hover experiment*/
/*
    .container {
    position: relative;
    width: 50%;
}
*/


.container {
    cursor: pointer;
}

.image {
    /*background-color: #ffffff;*/
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}

.container:hover img:nth-child(1) {
    opacity: 1;
}

.container:hover img:nth-child(2) {
    opacity: 1;
}

.container:hover img:nth-child(3) {
    opacity: 1;
}

.container:hover img:nth-child(4) {
    opacity: 1;
}

.container:hover img:nth-child(5) {
    opacity: 1;
}

.container:hover img:nth-child(6) {
    opacity: 1;
}

.container:hover img:nth-child(1):hover {
    opacity: 0.3;
}

.container:hover img:nth-child(2):hover {
    opacity: 0.3;
}

.container:hover img:nth-child(3):hover {
    opacity: 0.3;
}

.container:hover img:nth-child(4):hover {
    opacity: 0.3;
}

.container:hover img:nth-child(5):hover {
    opacity: 0.3;
}

.container:hover img:nth-child(6):hover {
    opacity: 0.3;
}



.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}



.container:hover .middle {
    opacity: 1;
}

.text {
    background-color: rgba(136, 136, 139, 0.5); /* Gray background with 60% opacity */
    color: white;
    font-size: 10px;
    padding: 16px 32px;
    opacity: 0.8;
    cursor: pointer;
}



/*image hover experiment*/

