


/*.image-composition {
    position: relative;
    width: 100%;
    height: auto;
}

.background-image {
    width: 100%;
    height: auto;
}

.logo-image,
.cover-image {
    position: absolute;
    width: 30%;*/ /* Adjust the width as needed */
/*height: auto;
}

.logo-image {
    left: 10%;*/ /* Adjust the left position as needed */
/*}

.cover-image {
    right: 10%;*/ /* Adjust the right position as needed */
/*}*/





/* A BOX - container To keep images fromm moving when changing browser window size */


:root {
    --default-Cover_Width: 50%;
    --default-Logo_Width: 12%;

}



.image-container {
    position: relative;
    width: 100%;
    height: auto;

    background: #0f0e13;
    max-height: 100%;
    color: var(--theme-color);
    border: solid 1px #2b2a30;
}



/* DEFAULT */



.default_image-composition {
    position: relative;
    width: 100%;
    height: auto;
}

.default_background-image {
    width: 100%;
    height: auto;

    opacity: 1;
    display: block;
    transition: .5s ease;
    backface-visibility: hidden;
}

.default_logo-image,
.default_cover-image {
    width: var(--default-Logo_Width);
    height: auto;
    position: absolute;
    top: 0;
}

.default_logo-image {
    border-radius: 50%;
    left: 10%; /* Adjust the left position as needed */
}

.default_cover-image {
    width: var(--default-Cover_Width);
    height: auto;
    right: 10%; /* Adjust the right position as needed */
}


.default_text-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 8px; /* Optional: Add rounded corners */
}



/* TEMPLATE 1 */
.template1_image-composition {
    position: relative;
    width: 100%;
    height: auto;
}



.template1_background-image {
    width: 100%;
    height: auto;
}

.template1_logo-image,
.template1_cover-image {
    width: var(--default-Logo_Width);
    height: auto;
    position: absolute;
    top: 0;
}

.template1_logo-image {
    border-radius: 50%;
    left: 86%; /* Adjust the left position as needed */
    top: 75%;
}

.template1_cover-image {
    width: var(--default-Cover_Width);
    height: auto;
    left: 3%; /* Adjust the right position as needed */
    top: 6%;
}





.template1_text-box {
    position: absolute;
    top: 35%;
    left: 56%;
    /*transform: translate(-50%, -50%);*/
    text-align: center;
    width: 40%; /* Set a fixed width */
    height: 30%; /* Set a fixed height */
    /*border-radius: 8px;*/ /* Optional: Add rounded corners */
}


.template1_text-box-Artist {
    position: absolute;
   
    /*transform: translate(-50%, -50%);*/
    text-align: center;
    width: 100%; /* Set a fixed width */
    height: 50%; /* Set a fixed height */
    /*border-radius: 8px;*/ /* Optional: Add rounded corners */
}

.template1_text-box-Song {
    position: absolute;
    top: 40%;
    /*transform: translate(-50%, -50%);*/
    text-align: center;
    width: 100%; /* Set a fixed width */
    height: 50%; /* Set a fixed height */
    /*border-radius: 8px;*/ /* Optional: Add rounded corners */
}




/* TEMPLATE 2 */
.template2_image-composition {
    position: relative;
    width: 100%;
    height: auto;
}

.template2_background-image {
    width: 100%;
    height: auto;
}

.template2_logo-image,
.template2_cover-image {
    width: var(--default-Logo_Width);
    height: auto;
    position: absolute;
    top: 0;
}

.template2_logo-image {
    border-radius: 50%;
    left: 10%; /* Adjust the left position as needed */
    top: 20%; /* Adjust the right position as needed */
}

.template2_cover-image {
    width: var(--default-Cover_Width);
    height: auto;
    right: 10%; /* Adjust the right position as needed */
}

.template2_text-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 8px; /* Optional: Add rounded corners */
}
