@font-face {
    font-family: 'ACBlur';
    src: url('/public/Fonts/ACBlur.ttf') format('truetype');
}
@font-face {
    font-family: 'CWDRKAGE';
    src: url('/public/Fonts/CWDRKAGE.TTF') format('truetype');
}
@font-face {
    font-family: 'Lycanthrope';
    src: url('/public/Fonts/Lycanthrope.ttf') format('truetype');
}
@font-face {
    font-family: 'SATYP';
    src: url('/public/Fonts/SATYP___.TTF') format('truetype');
}
@font-face {
    font-family: 'Shay Man Regular';
    src: url('/public/Fonts/Shay Man Regular.ttf') format('truetype');
}

/* Add more @font-face rules for additional fonts if needed */
