@import url('./constants.css');

.image-editor {
    color: var(--theme-color);
    border: solid 1px #2b2a30;
    max-height: 100%;
    height: 100%;
}


.image-editor__fixedSize {

    background: #0f0e13;
    height: 75%;
/*    max-height: 500px;
*/    position: relative;
}




.image-editor__preview {
    /*   height: 200px;
    width: 200px;
    background: black;
    border: solid 1px #2b2a30;
    position: absolute;
    left: 20px;
    top: 20px;*/
    /*    border-radius: 100%; */
    background: #0f0e13;
    height: 200px;
    max-height: 200px;    
    position: relative;
    /*    border-radius: 100%; */
}

.image-editor__cropper {
    background: #0f0e13;
/*    height: calc(100% - 190px);
*/    height: calc(63%);
/*    max-height: 100%;
*/    max-height: 70%;
    position: relative;
}

.image-editor__slider {
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    position: absolute;
}

.image-editor__cropper-overlay {
    transition: 0.5s;
}

.image-editor__cropper-overlay--faded {
    color: rgba(0, 0, 0, 0.9); /* Black with 90% opacity */
}

.image-editor__service {
    pointer-events: none;
    left: -100%;
    position: absolute;
}

.image-editor__reset-button {
    position: absolute;
    right: 20px;
    top: 20px;
    background: rgba(255, 255, 255, 0.1);
}

    .image-editor__reset-button:hover {
        fill: var(--theme-color);
        background: rgba(255, 255, 255, 0.2);
    }

.image-editor__reset-button--hidden {
    opacity: 0;
    visibility: hidden;
}


/*Album previe Image group*/




/*.image-composition {
    position: relative;
    width: 100%;
    height: auto;
}

.background-image {
    width: 100%;
    height: auto;
}

.logo-image,
.cover-image {
    position: absolute;
    width: 30%;*/ /* Adjust the width as needed */
    /*height: auto;
}

.logo-image {
    left: 10%;*/ /* Adjust the left position as needed */
/*}

.cover-image {
    right: 10%;*/ /* Adjust the right position as needed */
/*}*/



