/* In your CSS file */
.inox-button {
    width:100%;
    padding: 10px 20px;
    border: 1px solid #606060; /* Adjusted border color for more definition */
    background-image: linear-gradient(135deg, #f0f0f0 0%, #b3b3b3 50%, #f0f0f0 100%);
    color: #333;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    transition: transform 0.1s, box-shadow 0.1s, background-image 0.3s; /* Smooth transitions */
}

    .inox-button:hover {
        background-image: linear-gradient(135deg, #e0e0e0 0%, #a3a3a3 50%, #e0e0e0 100%);
    }

.inox-button-active {
    background-image: linear-gradient(135deg, #c0c0c0 0%, #909090 50%, #c0c0c0 100%);
    box-shadow: inset 0 0 10px #000000;
    transform: translateY(2px); /* Simulate the button being pressed down */
}





.svg-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%; /* Makes the button round */
    cursor: pointer;
    outline: none;
    width: 60px; /* Set a fixed width and height for round shape */
    height: 60px;
    background: linear-gradient(145deg, #252525, #2c2c2c);
    box-shadow: 5px 5px 10px #131313, -5px -5px 10px #3f3f3f;
    -moz-box-shadow: 5px 5px 10px #131313, -5px -5px 10px #3f3f3f; /* Vendor prefix for Firefox */
    stroke: gray; /* Light gray stroke color for contrast */
    fill: gray;
}

    .svg-button:hover {
        fill: #6691ff;
        stroke: #6691ff; /* Light gray stroke color for contrast */
    }

    .svg-button svg {
        width: 75%;
        height: 75%;
    }


.svg-button-active {
    box-shadow: 0 0 10px 5px #6691ff;
    -moz-box-shadow: 0 0 10px 5px #6691ff; /* Vendor prefix for Firefox */
    border-radius: 50%;
    fill: #6691ff;
    stroke: #6691ff; /* Light gray stroke color for contrast */
}



/*#7142ff
*/