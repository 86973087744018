.App {
    background-color: #292929;
    min-width: 700px;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}






.columns-container {
    display: flex;
    justify-content: space-between;
}

.columnTabs {
    width: 100%;
    text-Align: left;
    /*display: flex;*/ /* Use flexbox for vertical alignment */
    /*align-items: center;*/ /* Align items vertically in the center */
    /*justify-content: center;*/ /* Align items horizontally in the center */

    float: left;
}


.column {
    width: 100%;
    border: 1px solid gray;
    padding: 10px;
    /*text-Align: left;*/
    /*display: flex;*/ /* Use flexbox for vertical alignment */
    /*align-items: center;*/ /* Align items vertically in the center */
    /*justify-content: center;*/ /* Align items horizontally in the center */

    float: left;
}



.column2 {
    flex: 1;
    margin: 0 10px; /* Optional: adjust as needed for spacing between columns */


}


/*.image-editor {
    color: var(--theme-color);
    border: solid 1px #2b2a30;
    max-height: 100%;
    height: 100%;
}*/




.column13 {
    float: left;
    width: calc(33.33% - 3px);
    /*    border: 1px solid gray;*/
    padding: 2px;
    font-size: 12px;
    text-Align: center;
}

.column23 {
    float: left;
    width: calc(66.66% - 3px);
    border: 1px solid gray;
    padding: 2px;
    font-size: 12px;
    text-Align: center;
}


.column15 {
    float: left;
    width: calc(20% - 3px);
/*    border: 1px solid gray;
*/    padding: 2px;
    font-size: 12px;
    text-Align: center;

}



.column25 {
    float: left;
    width: calc(40% - 3px);
    /*    border: 1px solid gray;
*/ padding: 2px;
    font-size: 12px;
    text-Align: center;
}

.column35 {
    width: calc(60% - 3px);
    padding: 2px;
    font-size: 12px;
    text-align: center;
    display: flex;
    justify-content: space-evenly; /* or space-around */
    flex-wrap: wrap;
}

.column45 {
    width: calc(60% - 3px);
    padding: 2px;
    font-size: 12px;
    text-align: center;
    display: flex;
    justify-content: space-evenly; /* or space-around */
    flex-wrap: wrap;
}

.column75 {
    width: calc(75% - 3px);
    padding: 2px;
    font-size: 12px;
    text-align: center;
    display: flex;
    justify-content: space-evenly; /* or space-around */
    flex-wrap: wrap;
    padding-top: 10px;
}

.column100 {
    width: calc(100% - 3px);
    padding: 2px;
    font-size: 12px;
    text-align: center;
    display: flex;
    justify-content: space-evenly; /* or space-around */
    flex-wrap: wrap;
    padding-top: 10px;
}


.column13Header {
    width: calc(33.33% - 3px);
    border: 1px solid gray;
    padding: 2px;
    background-Color: #e9e1d6;
    color: blue;
    font-Weight: bold;
    text-Align: center;
    display: flex; /* Use flexbox for vertical alignment */
    align-items: center; /* Align items vertically in the center */
    justify-content: center; /* Align items horizontally in the center */
}

.column23Header {
    width: calc(66.66% - 3px);
    border: 1px solid gray;
    padding: 2px;
    background-Color: #e9e1d6;
    color: blue;
    font-Weight: bold;
    text-Align: center;
    display: flex; /* Use flexbox for vertical alignment */
    align-items: center; /* Align items vertically in the center */
    justify-content: center; /* Align items horizontally in the center */
}

.columnHeader {
    width: calc(33.33% - 3px);
    border: 1px solid gray;
    padding: 2px;
    background-Color: #e9e1d6;
    color: blue;
    font-Weight: bold;
    text-Align: center;
    display: flex; /* Use flexbox for vertical alignment */
    align-items: center; /* Align items vertically in the center */
    justify-content: center; /* Align items horizontally in the center */
}

.columnVideos {
    float: left;
    width: calc(20% - 10px);
    text-Align: center;
    padding-bottom: 10px;
    border: 1px solid black;
}

.row {
    font-size: 12px;
    padding-bottom: 10px;
    padding-top: 10px;
    border: 1px solid black;
}
    /* Clear floats after the columns */
    .row:after {
        content: "";
        display: table;
        clear: both;
    }




.flex-container-Tabs {
    padding: 0;
    margin: 2px;
    list-style: none;
    /*border: 1px solid silver;*/
    /*-ms-box-orient: horizontal;*/
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    text-align: center;
}



.flex-container {
    padding: 0;
    margin: 10px;
    list-style: none;
    border: 1px solid silver;
    /*-ms-box-orient: horizontal;*/
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    text-align: center;
}

.nowrap {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.wrap {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

    .wrap li {
       /*background: gold;*/
        background: #e9e1d6;
    }

.wrap-reverse {
    -webkit-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
}

    .wrap-reverse li {
        background: deepskyblue;
    }

.flex-item {
    /*background: tomato;*/
    padding: 5px;
    width: 170px;
    margin: 10px;
    /*line-height: 100px;*/
    color: black;
    font-weight: normal;
    /*    font-size: 2em; */
    font-size: 12px;
    text-align: center;
}



.progress-bar-container {
    width: 100%;
    text-Align: center;
    float: left;
justify-content:center;

}



.progress-bar {
    width: 100%;
    height: 20px;
    /*    background-color: #f0f0f0;
*/ 
    box-shadow: inset 10px 10px 19px #121212, inset -10px -10px 19px #404040;    
   border-radius: 10px;
    overflow: hidden;
}

.progress-bar-inner {
    height: 100%;
    /*    background-color: green;*/
    background-color: var(--theme-color);
    box-shadow: inset 5px 5px 10px #121212, inset -5px -5px 10px #404040;
    transition: width 0.3s ease-in-out;
    position: relative;
}

.progress-bar-fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transition: width 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: right;
}

.progress-label {
    color: lightgray;
    text-align: right;
    font-weight: bold;
    padding-right: 5px;
    padding-left: 5px;

}



.labelStyles {
    padding: 5px;
    color: white; 
    font-weight: bold;
}


.stepsHeader {
    font-size: 20px;
    text-align: left;
}






.tabs-container {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 20px;
  padding: 10px;
}

.tab-header {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.tab-header li {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #f2f2f2;
  border-radius: 4px 4px 0 0;
  margin-right: 5px;
}

.tab-header li:hover {
  background-color: #ddd;
}

.tab-header li.active {
  background-color: #fff;
  border-bottom: 2px solid #333;
}

.tab-content {
  padding: 20px;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 4px 4px 4px;
}





/* Hide the default text next to the file input button */
input[type="file"] + label:after {
    content: "";
    display: none;
}



/* Style the file input button as you wish */
input[type="file"] + label {
    /* Add your styling here */
    content: "";
    display: none;
}

/* Style the selected file name */
.selected-file-name {
    font-size: 14px;
    color: #333; /* Customize the color as needed */
    /* Add more styling properties as needed */
}


/*react-cropper react-cropper react-cropper */

.box {
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
}

.img-preview {
    overflow: hidden;
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
}

.cropper-crop-box,
.cropper-view-box {
    border-radius: 50%;
}

.cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    outline: 0;
}
/*react-cropper react-cropper react-cropper */




/*.image-composition {
    position: relative;
    width: 100%;
    height: auto;
}

.background-image {
    width: 100%;
    height: auto;
}

.logo-image,
.cover-image {
    position: absolute;
    width: 30%;*/ /* Adjust the width as needed */
    /*height: auto;
}

.logo-image {
    left: 10%;*/ /* Adjust the left position as needed */
/*}

.cover-image {
    right: 10%;*/ /* Adjust the right position as needed */
    /*top: 10%;*/ /* Adjust the left position as needed */
/*}*/


#fixed {
    width: 100px;
    height: 100px;
    border: 1px solid black;
    background: grey;
}

#font_size {
    background: black;
    color: white;
    width: 100px;
    min-height: 5px;
    padding: 1px;
}







.navbar {
    /*    height: 50px;
*/ height: 120px;
    /*    background-color: rgba(102,16,83,0698);
*/
    /*    background-color: black;
*/
    /*background: linear-gradient(to bottom, black, #292929);*/
    background: linear-gradient(to right, black 350px, black 0%, #292929 100%);
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 20px; /* Optional: Add padding to give some space on the sides */
    position: relative; /* Make navbar a positioning context for the absolute image */
}

.logo {
    font-size: 20px;
    font-weight: bold;
    flex-grow: 1; /* Allow the logo to take up available space */
}


.footer {
    min-height: 40px; /* Sets a minimum height */
    color: white;
    background-color: rgba(18, 18, 18, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px 20px; /* Adds top and bottom padding for flexibility */
    box-sizing: border-box; /* Ensures padding doesn't affect overall height */
    width: 100%; /* Ensures footer takes full width */
    flex-wrap: wrap; /* Allows footer content to wrap within the footer */
}


.footerleft {
    flex-grow: 1; /* Allow the logo to take up available space */
}


.list{
    display: flex;
    align-items: center;
    list-style:none;
}

.listItem {
    margin-right: 20px;
    font-weight: 500;
    /*    cursor:pointer;
*/
    position: relative; /* Important for dropdown positioning */
    color: white; /* Keep the text color white for list items */
}

.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;

}




/* List item with user.firstName */
.listItem.dropdown {
    position: relative;
    cursor: pointer; /* Set the pointer cursor only on the listItem containing the name */
    padding: 20px;
}


/* Dropdown styles */
.dropdownContent {
    display: none; /* Hidden by default */
    position: absolute;
    top: 100%; /* Position the dropdown below the listItem */
    right: 0;
    background-color: #333; /* Dark background for better contrast */
    /*    border: 1px solid #ccc;
*/
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1;
    min-width: 150px;
    list-style: none;
    margin: 0;
    padding: 0;
    color: white; /* Ensure text in dropdown is white */
}

.listItem.dropdown:hover .dropdownContent {
    display: block; /* Show the dropdown when hovering over the listItem */
    background-color: rgba(18, 18, 18, 0.5);
}

.dropdownContent li {
    padding: 10px;
    font-weight: 400;
    color: white;
    text-align: right; /* Align text inside the list items to the right */
    cursor: default; /* Ensure there's no pointer cursor on the dropdown items */
    background-color: transparent; /* No background change on hover */
}




.home{
    display:flex;
/*    padding:50px 100px;*/    
padding:20px 100px;
    justify-content: space-between;
    flex-wrap:wrap;
}



.card {
    width: 30%;
    padding: 10px;
    border-radius: 20px;
    -webkit-box-shadow: 0px 0px 8px 5px rgba(0,0,0,0.32);
    -moz-box-shadow: 0px 0px 8px 5px rgba(0,0,0,0.32);
    box-shadow: 0px 0px 8px 5px rgba(0,0,0,0.32);
}

.img{
    width:100%;
    object-fit:cover;
    margin:20px 0px;
}

.title {
    /*   color: var(--theme-color);    
*/
/*    color: #BF00FF;
*/
color: #007bff;


}

.desc {
    color: gray;
    margin-bottom: 20px;
    line-height: 25px;
}

.cardButton {
    border: none;
    border-radius: 10px;
    /*    background-color: rgba(53,16,102,0698);
*/

    background-color: #101720;
    padding: 10px;
    color: #3295ff;
    font-weight: bold;
    cursor: pointer;
}

.post{
    display:flex;
    flex-direction:column;
    align-items:center;

}

.postImg{
    width:100%;
    height:300px;
    object-fit:cover;
    margin-bottom:20px;
}

.postDesc{
    padding:0px 100px;
    font-size:24px;
    font-weight:300;
    color: gray;
    margin-top:50px;
}

.postLongDesc {
    padding:  100px;
    padding-top:50px;
    font-size: 18px;
    line-height:24px;
}

.login {
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 550px;
    text-align:center;

}

.wrapper {
    width: 60%;
    height: 75%;
    -webkit-box-shadow: 0px 0px 8px 5px rgba(0,0,0,0.32);
    -moz-box-shadow: 0px 0px 8px 5px rgba(0,0,0,0.32);
    box-shadow: 0px 0px 8px 5px rgba(0,0,0,0.32);
    display:flex;
    align-items:center;
    border-radius:20px;
}

.left,.right{
    flex:1;
    display:flex;
    flex-direction:column;
    align-items:center;
}

.center{
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    position:relative;
}

.or{
    border:2px solid lightgray;
    border-radius:50%;
    padding:10px;
    color:gray;
    background-color:white;
    font-weight:bold;

}

.line{
    width: 0.5px;
    height:70%;
    background-color:lightgray;
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    margin: auto;
    z-index:-1;
}

.loginButton{
    width:150px;
    padding: 15px 25px;
    border-radius:5px;
    color: white;
    display:flex;
    align-items:center;
    font-weight:bold;
    margin-bottom:20px;
    cursor:pointer;
}

.google {
    background-color: #df4930;
}
.facebook {
    background-color: #507cc0;
}
.github {
    background-color: black;
}

.icon{
    width:20px;
    height:20px;
    margin-right:10px;
}

.inputLogin{
    width: 200px;
    padding: 15px 20px;
    margin-bottom: 20px;
}

.submit {
    width: 200px;
    background-color: rgba(128,0,128,0.671);
    color: white;
    font-weight: bold;
    border: none;
    padding: 15px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.loginTitle{
    position:absolute;
    top:150px;
    color: lightgray;
}

.link{
    color:inherit;
    text-decoration:none;
}



    .link span {
        display: inline-flex;
        align-items: center;
        font-size: 1.5em; /* Adjust the font size as needed */
    }

    .link svg {
        height: 1em; /* Ensure SVG scales with text size */
        width: 1em; /* Ensure SVG scales with text size */
        margin-left: 0.5em; /* Space between text and SVG */
    }



/*for responsive cards*/
@media screen and (max-width: 992px) {
    .card {
        width: 100%;
        margin-bottom: 50px;
    }

    .wrapper {
        width: 65%;
        height: 90%;
        flex-direction: column;
    }

    .loginTitle {
        display: none;
    }

    .center {
        width: 100%;
        position: relative;
    }

    .left {
        padding: 20px;
    }

    .right {
        padding: 20px;
    }

    .line {
        height: 0.5px;
        width: 300px;
    }
}




.columnGoldenBig {
    float: left;
    width: calc(50% - 3px);
    padding: 15px;
    font-size: 12px;
    height: 95%;
    max-height: 100%;
    min-height: 530px;
    min-width: 300px;
    max-width: 100%;
    border-radius: 10px;
    background-color: #292929;
    color: var(--theme-color);
    border: solid 1px #2b2a30;
    box-shadow: 0px 0px 18px 5px rgba(18,18,18,0.75) inset;
    -webkit-box-shadow: 0px 0px 18px 5px rgba(18,18,18,0.75) inset;
    -moz-box-shadow: 0px 0px 18px 5px rgba(18,18,18,0.75) inset;
}

.columnGoldenSmall {
    float: left;
    width: calc(38.2% - 3px);
    padding: 15px;
    font-size: 12px;
    height: 95%;
    max-height: 100%;
    min-height: 530px;
    min-width: 300px;
    max-width: 100%;
    border-radius: 10px;
    background-color: #292929;
    color: var(--theme-color);
    border: solid 1px #2b2a30;
    box-shadow: 0px 0px 18px 5px rgba(18,18,18,0.75) inset;
    -webkit-box-shadow: 0px 0px 18px 5px rgba(18,18,18,0.75) inset;
    -moz-box-shadow: 0px 0px 18px 5px rgba(18,18,18,0.75) inset;
}



.main-application-container {
    display: flex;
    /*justify-content: center;*/
    align-items: center;
    height: calc(100vh - 40px);
    padding: 10px 10px;
    justify-content: space-between;
/*    flex-wrap: wrap;
*/   
min-height: 550px;
}

.nav-column {
    float: left;
    /*    width: calc(38.20% - 3px);*/
    width: calc(7% - 3px);
    /*    border: 1px solid gray;
*/ padding: 20px 20px; /* Padding: 20px top/bottom, 10px left/right */
    font-size: 12px;
    height: 95%;
    max-height: 100%;
    min-width: 70px;
    max-width: 100px;
    border-radius: 10px;
    background-color: #292929;
    color: var(--theme-color);
    --stroke-color: var(--theme-color);
    border: solid 1px #2b2a30;
    box-shadow: 0px 0px 18px 5px rgba(18,18,18,0.75) inset;
    -webkit-box-shadow: 0px 0px 18px 5px rgba(18,18,18,0.75) inset;
    -moz-box-shadow: 0px 0px 18px 5px rgba(18,18,18,0.75) inset;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* This will distribute the icons evenly */
    align-items: center; /* This will center the icons horizontally */
}

.svg-InColumn {
    cursor: pointer;
    min-width: 24px; /* Set your desired minimum width */
    min-height: 24px; /* Set your desired minimum height */
/*    fill: gray;
*/    /*stroke: gray;*/ /* Light gray stroke color for contrast */
}




.btn-with-icon {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border: none;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.btn-icon {
    margin-right: 10px;
    width: 35px; /* Adjust the width as needed */
    height: 35px; /* Adjust the height as needed */
}


.btn-with-icon:hover {
    background-color: #0056b3;
}

.containerUploadAudio {
    display: flex;
    justify-content: space-between;
/*    align-items: center;
*/    
/*border: 2px solid #007bff;
*/
padding: 0px;
    width: 100%; /* Adjust width as needed */
    box-sizing: border-box;
}

.simpleText{
    color:lightgray;
}
.textUploadAudio {
    flex: 1;
    padding: 10px;
}

/*.textAudioArea {
    border-radius: 5px;
    background: #ffffff;
    box-shadow: inset 7px 7px 9px #a3a3a3, inset -7px -7px 9px #ffffff;
    color: black;
}*/

.textAudioArea {
    border-radius: 3px;
    max-width: 100%;
    width: 100%;
    resize: none;
    color: lightgray;
    border: solid 1px #2b2a30;
    background: #292929;
    /*    box-shadow: inset 10px 10px 19px #121212, inset -10px -10px 19px var(--theme-color);
*/
    box-shadow: inset 10px 10px 19px #121212, inset -10px -10px 19px #6691ff; /*#7142ff*/
    /*    box-shadow: inset 10px 10px 19px #121212, inset -10px -10px 19px #404040;
*/
    padding: 5px; /* Add padding inside the textarea */
}





.container-radioButton{
    display: block;
    position: relative;
    padding-left: 20px;
    margin-bottom: 32px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

    /* Hide the browser's default radio button */
    .container-radioButton input{
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    .container-radioButton .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        background: #292929;
        box-shadow: inset 10px 10px 19px #121212, inset -10px -10px 19px #404040;
    }



    /* On mouse-over, add a grey background color */
    .container-radioButton:hover input ~ .checkmark {
        background-color: black;
        box-shadow: inset 10px 10px 19px #121212, inset -10px -10px 19px var(--theme-color);
        /*    box-shadow: inset 10px 10px 19px #121212, inset -10px -10px 19px #7142ff;
*/
    }




/* When the radio button is checked, add a blue background */
.container-radioButton input:checked ~ .checkmark {
    background-color: none;
}







/* Create the indicator (the dot/circle - hidden when not checked) */
    .container-radioButton .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }



/* Show the indicator (dot/circle) when checked */
.container-radioButton input:checked ~ .checkmark:after {
    display: block;
}




/* Style the indicator (dot/circle) */
.container-radioButton .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--theme-color);
    /*    background: #7142ff;
*/
}







.form-control {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.5em;
}

input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none; /* for Safari */
    -moz-appearance: none; /* for Firefox */

    position: absolute;
    top: 0;
    left: 0;
    background: #292929;
    box-shadow: inset 10px 10px 19px #121212, inset -10px -10px 19px #404040;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 2em;
    height: 2em;

    border-radius: 0.15em;
/*    transform: translateY(-0.075em);
*/    display: grid;
    place-content: center;
    cursor: pointer;
    position: relative; /* needed for pseudo-element positioning */
}

.form-control + .form-control {
    margin-top: 1em;
}

input[type="checkbox"]::before {
    content: "";
    width: 1em;
    height: 1em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color, currentColor);
    background-color: currentColor; /* Ensure the tick mark color is visible */
    position: absolute; /* Ensure positioning within checkbox */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0); /* Centering the pseudo-element */
    
/*  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);*/
}

input[type="checkbox"]:checked::before {
    transform: translate(-50%, -50%) scale(1);
}



input[type="checkbox"]:hover {
    background-color: black;
    box-shadow: inset 10px 10px 19px #121212, inset -10px -10px 19px var(--theme-color);
    /*    box-shadow: inset 10px 10px 19px #121212, inset -10px -10px 19px #7142ff;
*/
}


.checkbox-wrapper {
    position: relative;
    display: inline-block;
}


.checkbox-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px; /* Adjust as needed for spacing */
}


.checkbox-label {
    margin-bottom: 5px; /* Adjust as needed for spacing */
}


.checkboxTooltip {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position the tooltip above the button */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    width: max-content; /* Adjust the width as needed */
    white-space: nowrap; /* Prevent tooltip text from wrapping */
}

.checkbox-wrapper:hover .checkboxTooltip {
    visibility: visible;
    opacity: 1;
}

.checkbox-label {
    margin-bottom: 5px; /* Adjust as needed for spacing */
}


.gray-text {
    color: gray;
}










.button-container-ChooseImage {
    display: flex;
    justify-content: center; /* Centers the buttons horizontally */
    /*gap: 10px;*/ /* Adjust the gap between buttons as needed */
    /*margin: 0 auto;*/ /* Centers the container itself horizontally */
    padding: 10px;
}

.button-ChooseImage {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}


.centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



.blueLine {
    height: 2px;
    margin-top: 10px;
    background: linear-gradient(to right, transparent, var(--theme-color), transparent);
}




/*canvas command overlays
*/
/* AudioVisualizer34e.css */

.audio-visualizer {
    position: relative;
}

.canvas-container {
    position: relative;
    width: 400px; /* Set to match your canvas size */
    height: 300px; /* Set to match your canvas size */
}

    .canvas-container canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0); /* Transparent background */
    cursor: pointer; /* Change cursor to indicate clickable area */
    border: none;
    box-shadow: none;
    transition: background-color 0.3s, border 0.3s, box-shadow 0.3s;
}

    .overlay.hover {
        background-color: rgba(211, 211, 211, 0.6); /* Background color on hover */
        border: 1px solid #00717f; /* Border color on hover */
        box-shadow: 0 0 8px 2px cyan; /* Neon glow effect on hover */
    }

.hover-cover-text {
    visibility: hidden; /* Show text only on hover */
    position: absolute; /* Position the text absolutely within the relative overlay div */
    top: 50%; /* Center vertically in the middle */
    left: 50%; /* Center horizontally in the middle */
    transform: translate(-50%, -50%); /* Adjust position to truly center */
    font-size: 32px; /* Small text size */
    font-weight: bold; /* Make the text bold */
    color: #00262a; /* Text color */
    margin: 0; /* Reset margins */
    padding: 0; /* Reset padding */
    text-shadow: 0 0 8px 2px cyan, 0 0 10px 2px cyan, 0 0 12px 2px cyan; /* Neon glow effect */
    text-align: center; /* Ensure text itself is centered if it breaks into multiple lines */
}

.overlay.hover .hover-cover-text {
    visibility: visible; /* Show text on hover */
}


.select-dropdown {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    font-size: 16px;
    color: lightgray;
    border: solid 1px #2b2a30;
    background: #292929;
    /*    box-shadow: inset 10px 10px 19px #121212, inset -10px -10px 19px var(--theme-color);
*/
    box-shadow: inset 10px 10px 19px #121212, inset -10px -10px 19px #6691ff; /*#7142ff*/
    /*    box-shadow: inset 10px 10px 19px #121212, inset -10px -10px 19px #404040;
*/
}

    .select-dropdown option {
        padding: 5px;
        font-size: 14px;
    }



.color-picker {
    width: 70%;
    height:3em;
    padding:5px;
    border-radius: 5px;
    color: lightgray;
    border: solid 1px #2b2a30;
    background: #292929;
    box-shadow: inset 10px 10px 19px #121212, inset -10px -10px 19px #404040;
    cursor: pointer;
}

.opacity-slider {
    border-radius: 5px;
    color: lightgray;
    border: solid 1px #2b2a30;
    background: #292929;
    box-shadow: inset 10px 10px 19px #121212, inset -10px -10px 19px #404040;
    cursor: pointer;
}






.video-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px; /* Same width as the video */
    margin-top: 0px; /* Add some space between video and footer */
}

.video-title {
    flex: 4; /* Take up 4/5 of the space */
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.download-button-container {
    flex: 1; /* Take up 1/5 of the space */
    display: flex;
    justify-content: flex-end;
}












.styled-scrollbar::-webkit-scrollbar {
    width: 12px; /* Width of the entire scrollbar */
}

.styled-scrollbar::-webkit-scrollbar-track {
    background: transparent; /* Background color of the scrollbar track */
}

.styled-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--theme-color); /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners of the scrollbar thumb */
    border: 3px solid transparent; /* Padding around the thumb */
}

    .styled-scrollbar::-webkit-scrollbar-thumb:hover {
        background-color: #555; /* Color of the scrollbar thumb on hover */
    }


.styled-scrollbar {
    scrollbar-width: thin; /* Sets the width of the scrollbar */
    scrollbar-color: var(--theme-color) rgba(0, 0, 0, 0); /* Thumb color and transparent track */
}















audio::-webkit-media-controls-enclosure {
    border-radius: 0;
    background: rgba(0, 123, 255, 0.7);
    box-shadow: inset 15px 15px 19px #292929, inset -15px -15px 19px #292929;
    opacity: 90%;
}
    audio::-webkit-media-controls-enclosure:hover {
        background: rgba(0, 123, 255, 0.8);
        opacity: 100%;
    }


audio::-webkit-media-controls-panel {
    /*background: var(--theme-color);
    box-shadow: inset 10px 10px 19px #292929, inset -10px -10px 19px #292929;*/
}


audio::-webkit-media-controls-play-button {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(100%) contrast(100%);
}

    audio::-webkit-media-controls-play-button:hover {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 50%;
    }



audio::-webkit-media-controls-timeline {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(100%) contrast(100%);
}

audio::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display {
    color: white;
}

audio::-webkit-media-controls-mute-button {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(100%) contrast(100%);
}

    audio::-webkit-media-controls-mute-button:hover {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 50%;
    }

audio::-webkit-media-controls-volume-slider {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(100%) contrast(100%);
}

audio::-webkit-media-controls-seek-back-button,
audio::-webkit-media-controls-seek-forward-button {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(100%) contrast(100%);
}






.left-align {
    text-align: left;
}

.right-align {
    text-align: right;
}



.privacy-policy {
    

    padding: 20px 50px;
    justify-content: space-between;
    flex-wrap: wrap;

    border-radius: 10px;
    background-color: #292929;
    color: var(--theme-color);
    border: solid 1px #2b2a30;
    box-shadow: 0px 0px 18px 5px rgba(18,18,18,0.75) inset;
    -webkit-box-shadow: 0px 0px 18px 5px rgba(18,18,18,0.75) inset;
    -moz-box-shadow: 0px 0px 18px 5px rgba(18,18,18,0.75) inset;
}



.simplePage-container {
    /*justify-content: center;*/
    align-items: center;
    padding: 10px 10px;
  

}


.loginContainer {
    padding: 20px 50px;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 10px;
    background-color: #292929;
    color: var(--theme-color);
    border: solid 1px #2b2a30;
    -webkit-box-shadow: 0px 0px 8px 5px rgba(0,0,0,0.32);
    -moz-box-shadow: 0px 0px 8px 5px rgba(0,0,0,0.32);
    box-shadow: 0px 0px 8px 5px rgba(0,0,0,0.32);
}

.loginPage{
     display: flex;
    /*justify-content: center;*/
    align-items: center;
    height: calc(100vh - 100px);
    padding: 10px 10px;
/*    flex-wrap: wrap;
*/   
min-height: 550px;
}






.editButton-wrapper {
    position: relative;
    display: inline-block;
}




.editButtonTooltip {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: 50%; /* Align tooltip vertically to the middle of the button */
    left: 105%; /* Position tooltip to the right of the button */
    transform: translateY(-50%); /* Adjust vertical centering */
    opacity: 0;
    transition: opacity 0.3s;
    width: max-content; /* Adjust the width as needed */
    white-space: nowrap; /* Prevent tooltip text from wrapping */
}

.editButton-wrapper:hover .editButtonTooltip {
    visibility: visible;
    opacity: 1;
}




/* Parent container */
.home-page {
    text-align: center; /* Center text alignment */
}



/* Each section takes up the full viewport */
.home-section {
    /*height: 100vh;*/ /* Ensure each section takes up the full viewport */
    display: flex; /* Use flexbox for alignment */
    flex-direction: column; /* Stack content vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    text-align: center; /* Center-align text */
}

/* Section title styling */
.home-section-title {
    color: #007bff; /* Title color */
    font-size: 2rem; /* Font size */
    margin-top: 20px; /* Spacing below title */

    margin-bottom: 10px; /* Spacing below title */
}

.home-section-title2 {
    color: #007bff; /* Title color */
    font-size: 1.6rem; /* Font size */
    margin-bottom: 10px; /* Spacing below title */
}

/* Section description styling */
.home-section-description {
    color: gray; /* Description text color */
    font-size: 1.2rem; /* Font size for description */
    margin-bottom: 20px; /* Spacing below description */
    line-height: 25px; /* Line height for better readability */
}

.home-section-tagline {
    color: #007bff; /* Match title color for consistency */
    font-size: 1.2rem; /* Same size as description */
    font-style: italic; /* Distinguish tagline with styling */
    margin-top: 10px;
}

.home-section-image {
    max-width: 35%; /* Ensures the image scales with its container */
    height: auto; /* Maintains aspect ratio */
    margin: 20px 0; /* Adds spacing around the image */
    border-radius: 10px; /* Optional: Add rounded corners for a modern look */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add subtle shadow */
}


.home-section-image-BIG {
    max-width: 65%; /* Ensures the image scales with its container */
    height: auto; /* Maintains aspect ratio */
    margin: 20px 0; /* Adds spacing around the image */
    border-radius: 10px; /* Optional: Add rounded corners for a modern look */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add subtle shadow */
}


/*.sectionButton {
    border: none;
    border-radius: 10px;
    background-color: #101720;
    padding: 12px;
    color: #3295ff;
    font-weight: bold;
    cursor: pointer;
    font-size: 2rem;*/ /* Font size for description */
/*}*/


/* Call to Action Button */
.sectionButton {
    padding: 12px 24px; /* Adjust padding for button size */
    font-size: 1.6rem; /* Slightly larger text for emphasis */
    color: white; /* Text color */
    background-color: #007bff; /* Primary blue color */
    border: none; /* Remove default borders */
    border-radius: 8px; /* Rounded corners for modern look */
    cursor: pointer; /* Show pointer on hover */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
}

    .sectionButton:hover {
        background-color: #0056b3; /* Darker shade of blue on hover */
        transform: scale(1.05); /* Slight zoom effect on hover */
    }

    .sectionButton:active {
        transform: scale(0.95); /* Slight shrink effect when clicked */
        background-color: #004080; /* Even darker shade for click feedback */
    }

/* Optional: Add responsive styling for smaller screens */
@media (max-width: 600px) {
    .sectionButton {
        font-size: 1rem; /* Slightly smaller font for small screens */
        padding: 10px 20px; /* Adjust padding */
    }
}





/* Call to Action Button */
.patreonButton {
    background-color: #FF424D; /* Vibrant red background */
    color: #FFF; /* White text color */
    border: none; /* Remove default border */
    padding: 5px 10px; /* Compact padding for smaller size */
    font-size: 16px; /* Default font size */
    border-radius: 5px; /* Rounded corners for modern look */
    cursor: pointer; /* Pointer on hover */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
}

    .patreonButton:hover {
        background-color: #E6393F; /* Slightly darker red on hover */
        transform: scale(1.05); /* Slight zoom effect */
    }

    .patreonButton:active {
        transform: scale(0.95); /* Slight shrink effect when clicked */
        background-color: #C12F36; /* Even darker red for click feedback */
    }

/* Optional: Responsive Design */
@media (max-width: 600px) {
    .patreonButton {
        font-size: 14px; /* Slightly smaller font for smaller screens */
        padding: 8px 16px; /* Adjust padding */
    }
}




/* Discord Button */
.discordButton {
    background-color: #7289DA; /* Discord's brand color */
    color: #FFF; /* White text */
    border: none; /* Remove default border */
    padding: 5px 10px; /* Compact padding */
    font-size: 16px; /* Font size */
    border-radius: 5px; /* Rounded corners for a modern look */
    cursor: pointer; /* Pointer on hover */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
}

    .discordButton:hover {
        background-color: #5B6EAE; /* Slightly darker blue on hover */
        transform: scale(1.05); /* Slight zoom effect */
    }

    .discordButton:active {
        transform: scale(0.95); /* Slight shrink effect when clicked */
        background-color: #4A5A91; /* Even darker blue for click feedback */
    }

/* Optional: Responsive Design */
@media (max-width: 600px) {
    .discordButton {
        font-size: 14px; /* Smaller font for smaller screens */
        padding: 8px 16px; /* Adjust padding */
    }
}





/* Ko-fi Button */
.kofiButton {
    display: flex;
    align-items: center;
    background-color: #29abe0; /* Ko-fi's brand color */
    color: #FFF; /* White text */
    border: none; /* Remove default border */
    padding: 5px 15px; /* Compact padding */
    font-size: 16px; /* Font size */
    border-radius: 5px; /* Rounded corners for a modern look */
    cursor: pointer; /* Pointer on hover */
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
}


.kofi-logo {
    height: 1.5em; /* Scale relative to text size */
    width: auto; /* Maintain aspect ratio */
    max-height: 40px; /* Prevent excessive scaling */
    margin-right: 8px;
}


    .kofiButton:hover {
        background-color: #1a8fc0; /* Slightly darker blue on hover */
        transform: scale(1.05); /* Slight zoom effect */
    }

    .kofiButton:active {
        transform: scale(0.95); /* Slight shrink effect when clicked */
        background-color: #136d90; /* Even darker blue for click feedback */
    }

/* Optional: Responsive Design */
@media (max-width: 600px) {
    .kofiButton {
        font-size: 14px; /* Slightly smaller font for small screens */
        padding: 8px 16px; /* Adjust padding */
    }
}




.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.modal-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}


.continue-button {
    background: #555;
    color: white;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
}



/*#007BFF*/





