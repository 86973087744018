
.image-editor-navigation {
    background: #1b1a21;
    /*    height: 84px;*/ 
    height: 5em;
    border-top: solid 1px #2b2a30;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    /*flex-wrap: wrap;*/ /* Allow items to wrap if necessary */
    flex-wrap: nowrap; /* Prevent items from wrapping */
}


.image-editor-leftright {
   
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
    /*flex-wrap: wrap;*/ /* Allow items to wrap if necessary */
    flex-wrap: nowrap; /* Prevent items from wrapping */
}

.image-editor-navigation__left,
.image-editor-navigation__right {
    align-items: center;
}

.image-editor-navigation__left {
    justify-content: flex-start;
}

.image-editor-navigation__right {
    justify-content: flex-end;
}



.image-editor-navigation__button {
    position: relative; /* Needed for tooltip positioning */
    margin-left: 4px;
    margin-right: 4px;
    /*flex: 1 0 auto;*/ /* Allow buttons to grow and shrink */
    min-width: 24px; /* Set your desired minimum width */
    min-height: 24px; /* Set your desired minimum height */
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1; /* Allow buttons to shrink and grow */
    width: 35px; /* Set initial width */
    height: 35px; /* Set initial height */
}


    .image-editor-navigation__button .tooltip {
        visibility: hidden;
        background-color: #555;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        bottom: 125%; /* Position the tooltip above the button */
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity 0.3s;
        width: max-content; /* Adjust the width as needed */
        white-space: nowrap; /* Prevent tooltip text from wrapping */
    }

    .image-editor-navigation__button:hover .tooltip {
        visibility: visible;
        opacity: 1;
    }



    .image-editor-navigation__button svg {
        width: 100%;
        height: auto;
        max-width: 35px; /* Set max dimensions */
        max-height: 35px; /* Set max dimensions */
    }

.image-editor-navigation__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; /* Allow items to wrap if necessary */
   /* flex-wrap: nowrap;*/ /* Prevent items from wrapping */
}

.image-editor-navigation__upload-input {
    display: none;
}

/*@media (max-width: 540px) {
*/
@media (max-width: 1200px) {
    .image-editor-navigation {
        padding-left: 4px;
        padding-right: 4px;
        flex-wrap: wrap; /* Allow items to wrap if necessary */
    }

    .image-editor-navigation__button {
        margin-left: 4px;
        margin-right: 4px;
        flex: 1 1 auto; /* Allow buttons to grow and shrink */
        max-width: 30px; /* Set max dimensions */
        max-height: 30px; /* Set max dimensions */
    }

        .image-editor-navigation__button svg {
            width: 100%;
            height: auto;
            max-width: 30px; /* Set max dimensions */
            max-height: 30px; /* Set max dimensions */
        }
}


@media (max-width: 768px) {
    .image-editor-navigation {
        padding-left: 2px;
        padding-right: 2px;
        flex-wrap: wrap; /* Allow items to wrap if necessary */
    }

    .image-editor-navigation__button {
        margin-left: 2px;
        margin-right: 2px;
        flex: 1 1 auto; /* Allow buttons to grow and shrink */
        max-width: 20px; /* Set max dimensions */
        max-height: 20px; /* Set max dimensions */
    }

        .image-editor-navigation__button svg {
            width: 100%;
            height: auto;
            max-width: 20px; /* Set max dimensions */
            max-height: 20px; /* Set max dimensions */
        }
}









/*.image-editor-navigation {
    background: #1b1a21;
    height: 84px;
    border-top: solid 1px #2b2a30;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    flex-wrap: wrap;*/ /* Allow items to wrap if necessary */
/*}

.image-editor-navigation__button {
    margin-left: 8px;
    margin-right: 8px;*/
    /*flex: 1 0 auto;*/ /* Allow buttons to grow and shrink */
    /*min-width: 18px;*/ /* Set your desired minimum width */
    /*min-height: 18px;*/ /* Set your desired minimum height */
    /*display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;*/ /* Allow buttons to shrink and grow */
    /*width: 30px;*/ /* Set initial width */
    /*height: 30px;*/ /* Set initial height */

/*}*/

  /*  .image-editor-navigation__button svg {
        width: 30px; 
        height: 30px; 
    }*/


/*.image-editor-navigation__buttons {
    display: flex;
    align-items: center;
    justify-content: center;  
    flex-wrap: wrap;*/ /* Allow items to wrap if necessary */
/*}

.image-editor-navigation__upload-input {
    display: none;
}*/

/*@media (max-width: 540px) {
*/
/*@media (max-width: 1040px) {
    .image-editor-navigation {
        padding-left: 8px;
        padding-right: 8px;
        flex-wrap: wrap;*/ /* Allow items to wrap if necessary */
    /*}

    .image-editor-navigation__button {
        margin-left: 4px;
        margin-right: 4px;
        flex: 1 1 auto;*/ /* Allow buttons to grow and shrink */
        /*max-width: 18px;*/ /* Set max dimensions */
        /*max-height: 18px;*/ /* Set max dimensions */
    /*}

        .image-editor-navigation__button svg {
            width: 100%;
            height: auto;
            max-width: 18px;*/ /* Set max dimensions */
            /*max-height: 18px;*/ /* Set max dimensions */
        /*}

  
}*/








/*

.image-editor-navigation {
    background: #1b1a21;
    height: 84px;
    border-top: solid 1px #2b2a30;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
}

.image-editor-navigation__button {
    margin-left: 8px;
    margin-right: 8px;
}

.image-editor-navigation__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-editor-navigation__upload-input {
    display: none;
}

@media (max-width: 540px) {
    .image-editor-navigation {
        padding-left: 8px;
        padding-right: 8px;
    }

    .image-editor-navigation__button {
        margin-left: 4px;
        margin-right: 4px;
    }
}*/
