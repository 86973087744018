*{
    margin:0;
    padding:0;
    box-sizing:border-box;
}

#container {
    position: absolute;
    top:0;
    left:0;
    background:black;
    width: 100%;
    height: 100%;
}

#canvas1 {
    /*
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
        */
   /* filter: blur(2px) contrast(20);*/
}

#audio1 {
    width: 50%;
margin: 50px auto;
display: block;
}

#fileupload{
    position:absolute;
    top: 150px;
    color: white;
    z-index:100;
}